<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.name"
        placeholder="请输入学生姓名"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-select
        v-model="listQuery.is_normal"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="冻结" :value="0" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <div class="filter-container">
      <!-- <el-button class="filter-item" type="success" @click="downExcel">
        <a
          href="https://eclass-img.jcqjmk.com/student-import-temp.xlsx"
          target="_parent"
        >
          下载学生模板
        </a>
      </el-button> -->
      <!-- <el-button class="filter-item" style="margin-left: 10px" type="success">
        <a
          href="https://eclass-img.jcqjmk.com/student-import-temp.xlsx"
          target="_parent"
        >
          下载实习模板
        </a>
      </el-button> -->
      <download-excel
        :fields="practice_fields"
        :fetch="handleExport"
        type="xls"
        name="实习模板.xls"
        worksheet="实习模板"
      >
        <el-button class="filter-item" style="margin-left: 10px" type="warning">
          下载实习模板
        </el-button>
      </download-excel>
      <el-upload
        class="upload-demo"
        style="margin-left: 10px"
        accept=".xlsx,.xls"
        action=""
        :show-file-list="false"
        :auto-upload="false"
        :on-change="handleImportOrders"
      >
        <el-button class="filter-item" type="primary" icon="el-icon-upload">
          导入实习信息
        </el-button>
      </el-upload>
      <download-excel
        :fields="json_fields"
        :fetch="handleExport"
        type="xls"
        name="实习信息.xls"
        worksheet="实习信息"
        :header="header"
      >
        <el-button class="filter-item" style="margin-left: 10px" type="warning">
          导出实习信息
        </el-button>
      </download-excel>
      <download-excel
        :fields="student_fields"
        :fetch="handleExport"
        type="xls"
        :name="downName + '-学生花名册.xls'"
        worksheet="学生花名册"
        :header="header"
      >
        <el-button class="filter-item" style="margin-left: 10px" type="success">
          下载学生花名册
        </el-button>
      </download-excel>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="学生姓名" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.student.name }}</template>
      </el-table-column>
      <el-table-column label="学号" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.student.account }}</template>
      </el-table-column>
      <el-table-column label="院系" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.classes.college.name }}
        </template>
      </el-table-column>
      <el-table-column label="专业" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.classes.major.name }}
        </template>
      </el-table-column>
      <el-table-column label="年级" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.classes.grade.name }}
        </template>
      </el-table-column>
      <el-table-column label="班级" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.classes.name }}</template>
      </el-table-column>
      <el-table-column label="学年" min-width="110" align="center">
        <template slot-scope="scope">
          {{
            scope.row.classes.grade
              ? scope.row.classes.school_year +
                "-" +
                (scope.row.classes.school_year - 0 + 1)
              : "-"
          }}
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.student.is_normal"
            >正常</el-tag
          >
          <el-tag type="danger" v-else>冻结</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <!-- <el-button
            size="mini"
            type="primary"
            @click="handleCheckCurriculum(scope.row)"
            >查看课表</el-button
          > -->
          <el-button
            size="mini"
            type="primary"
            @click="handleUpdate(scope.row)"
          >
            实习信息
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      title="查看实习"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="200px"
        v-if="form.id"
      >
        <el-form-item label="学生姓名">
          {{ form.student.name }}
        </el-form-item>
        <el-form-item label="学号">
          {{ form.student.account }}
        </el-form-item>
        <el-form-item label="院系">
          {{ form.classes.college.name }}
        </el-form-item>
        <el-form-item label="专业">
          {{ form.classes.major.name }}
        </el-form-item>
        <el-form-item label="年级">
          {{ form.classes.grade.name }}
        </el-form-item>
        <el-form-item label="班级">
          {{ form.classes.name }}
        </el-form-item>
        <el-form-item label="学年">
          {{ form.classes.school_year }}-{{ form.classes.school_year - 0 + 1 }}
        </el-form-item>
        <el-form-item label="性别" prop="class_student_internship_log.sex">
          <el-select
            v-model="form.class_student_internship_log.sex"
            placeholder="性别"
            clearable
            style="width: 150px"
            class="filter-item"
          >
            <el-option label="男" :value="1" />
            <el-option label="女" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item
          label="联系方式"
          prop="class_student_internship_log.contact_phone"
        >
          <!-- {{form.class_student_internship_log.sex}} -->
          <el-input
            type="text"
            v-model="form.class_student_internship_log.contact_phone"
            clearable
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item
          label="现实习公司"
          prop="class_student_internship_log.now_internship_company"
        >
          <el-input
            type="text"
            v-model="form.class_student_internship_log.now_internship_company"
            clearable
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item
          label="紧急联系人"
          prop="class_student_internship_log.urgent_contact"
        >
          <el-input
            type="text"
            v-model="form.class_student_internship_log.urgent_contact"
            clearable
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item
          label="紧急联系人电话"
          prop="class_student_internship_log.urgent_contact_phone"
        >
          <el-input
            type="text"
            v-model="form.class_student_internship_log.urgent_contact_phone"
            clearable
            style="width: 200px"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="导入数据预览"
      :visible.sync="importDataDialogVisible"
      center
      width="60%"
      :close-on-click-modal="false"
    >
      <div style="height: 500px" class="scrollbar">
        <el-scrollbar style="height: 500px">
          <el-table :data="yulanData" style="width: 1100px">
            <el-table-column label="学生姓名" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.student_name || "" }}
              </template>
            </el-table-column>
            <el-table-column label="学号" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.student_no || "" }}
              </template>
            </el-table-column>
            <el-table-column label="性别" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.sex || "" }}
              </template>
            </el-table-column>
            <el-table-column label="联系方式" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.contact_phone }}
              </template>
            </el-table-column>
            <el-table-column label="现实习公司" min-width="80" align="center">
              <template slot-scope="scope">
                {{ scope.row.now_internship_company }}
              </template>
            </el-table-column>
            <el-table-column label="紧急联系人" min-width="80" align="center">
              <template slot-scope="scope">
                {{ scope.row.urgent_contact }}
              </template>
            </el-table-column>
            <el-table-column
              label="紧急联系人电话"
              min-width="80"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.urgent_contact_phone }}
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="importDataDialogVisible = false">取消</el-button>
        <el-button @click="importSaveData" type="primary" :loading="btnLoading"
          >确认导入</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import { linkProblemData } from "@/api/investment&financing/founder";
import downloadExcel from "vue-json-excel";
import { excel2Json } from "@/utils/excel";
export default {
  components: { downloadExcel },
  data() {
    return {
      list: [],
      header: "",
      total: null,
      college_id: null,
      students: [],
      listLoading: false,
      importDataDialogVisible: false,
      listQuery: {
        page: 1,
        limit: 10,
        name: "",
        is_normal: "",
        class_id: "",
      },
      textMap: { update: "修改", create: "新增" },
      dialogFormVisible: false,
      dialogStatus: "",
      btnLoading: false,
      form: {
        id: "",
        class_id: "",
        student_id: "",
      },
      rules: {
        student_id: [
          { required: true, message: "学生姓名不能为空", trigger: "change" },
        ],
        student_account: [
          { required: true, message: "学生账号不能为空", trigger: "change" },
        ],
        "class_student_internship_log.sex": [
          { required: true, message: "请选择性别", trigger: "change" },
        ],
        "class_student_internship_log.now_internship_company": [
          { required: true, message: "实习公司不能为空", trigger: "change" },
        ],
        "class_student_internship_log.contact_phone": [
          { required: true, message: "联系电话不能为空", trigger: "change" },
        ],
        "class_student_internship_log.urgent_contact": [
          { required: true, message: "紧急联系人不能为空", trigger: "change" },
        ],
        "class_student_internship_log.urgent_contact_phone": [
          {
            required: true,
            message: "紧急联系人电话不能为空",
            trigger: "change",
          },
        ],
      },
      academys: [], //院系
      grade_id: "",
      tableData: [],
      yulanData: [],
      downName: "",
      //导出学生花名册
      student_fields: {
        序号: "index",
        "入学时间(格式:xxxx/xx/xx)": "student.entrance_at",
        "院系（请认证核对管理系统相关院系）": "classes.college.name",
        学生名称: "student.name",
        性别: {
          field: "student.sex",
          //自定义回调函数
          callback: (value) => {
            let val = "";
            if (value == 1) {
              val = "男";
            } else {
              val = "女";
            }
            return "<p style=\"mso-number-format:'\\@';\">" + val + "</p>";
          },
        },
        出生年月: {
          field: "student.birth_info",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        学生学号: {
          field: "student.account",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        学生联系电话: "student.phone",
        家长姓名: "student.parent_name",
        家长联系电话: "student.parent_phone",
      },
      //导出
      json_fields: {
        学生姓名: "student.name",
        "账号/学号": "student.account",
        院系: "classes.college.name",
        专业: "classes.major.name",
        年级: "classes.grade.name",
        班级: "classes.name",
        学年: "classes.grade.school_year",
        联系方式: "class_student_internship_log.contact_phone",
        现实习公司: "class_student_internship_log.now_internship_company",
        紧急联系人: "class_student_internship_log.urgent_contact",
        紧急联系人电话: "class_student_internship_log.urgent_contact_phone",
      },
      //实习模板
      practice_fields: {
        "院系（请认证核对管理系统相关院系）": "classes.college.name",
        学生名称: "student.name",
        学生学号: "student.account",
        联系方式: "student.phone",
        性别: "classes.ddd",
        现实习公司: "classes.ddd",
        紧急联系人: "classes.ddd",
        紧急联系人电话: "classes.ddd",
      },
    };
  },
  created() {
    this.form.class_id = this.$route.query.id;
    this.grade_id = this.$route.query.grade_id;
    this.listQuery.class_id = this.$route.query.id;
    this.college_id = this.$route.query.colleage_id;
    console.log(this.$route.query.colleage_id);
    this.getList();
    // this.getStudentList();
  },
  methods: {
    handleExport() {
      this.listQuery.limit = 99999999999999;
      return request({
        url: "/api/teacherend/school/classStudent",
        method: "get",
        params: this.listQuery,
      })
        .then((result) => {
          console.log(result.data.data);
          var json_data = result.data.data;
          json_data.forEach((element, index) => {
            element.index = index + 1;
          });
          // console.log(json_data[0].classes.college.school.name);
          this.header =
            json_data[0].classes.college.school.name +
            json_data[0].classes.college.name +
            json_data[0].classes.major.name +
            json_data[0].classes.grade.name +
            json_data[0].classes.name;
          return json_data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    formatDate(numb, format) {
      const time = new Date((numb - 1) * 24 * 3600000 + 1);
      time.setYear(time.getFullYear() - 70);
      const year = time.getFullYear() + "";
      const month = time.getMonth() + 1 + "";
      const date = time.getDate() - 1 + "";
      if (format && format.length === 1) {
        return (
          year +
          format +
          (month < 10 ? "0" + month : month) +
          format +
          (date < 10 ? "0" + date : date)
        );
      }
      return (
        year +
        (month < 10 ? "0" + month : month) +
        (date < 10 ? "0" + date : date)
      );
    },
    handleImportOrders(file) {
      this.yulanData = [];
      file = file.raw;
      if (
        !(file.name.split(".")[1] == "xlsx" || file.name.split(".")[1] == "xls")
      ) {
        this.$message.error("上传文件格式不正确，请检查选择的文件");
        return;
      }
      excel2Json(file, 2).then((excelData) => {
        // 处理业务
        if (excelData.length <= 0) {
          this.$message.error("数据不能为空");
          return;
        }
        excelData.forEach((element, index) => {
          if (index > 0) {
            this.yulanData.push({
              student_name: element[1] || "",
              student_no: element[2] || "",
              contact_phone: element[3] || "",
              sex: element[4] || "",
              now_internship_company: element[5] || "",
              urgent_contact: element[6] || "",
              urgent_contact_phone: element[7] || "",
            });
          }
        });
        this.importDataDialogVisible = true;
      });
    },
    importSaveData() {
      for (let index = 0; index < this.yulanData.length; index++) {
        const item = this.yulanData[index];
        for (var key in item) {
          if (!item[key]) {
            this.$message({
              type: "error",
              message: "请完善数据!!",
            });
            return;
          }
        }
      }
      let data = this.yulanData;
      data.forEach((item) => {
        if (item.sex == "男") {
          item.sex = 1;
        } else {
          item.sex = 2;
        }
        delete item.student_name;
      });
      request({
        url: "/api/teacherend/school/importClassStudentInternship",
        method: "post",
        data: {
          class_id: this.$route.query.id,
          datas: data,
        },
      })
        .then((response) => {
          console.log(response);
          this.btnLoading = false;
          this.importDataDialogVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
        .catch((err) => {
          this.btnLoading = false;
          this.$forceUpdate(); //强制更新
          console.log(this.btnLoading);
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    handleDel(row) {
      this.$confirm("此操作将该学生移除班级, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/school/classDelStudent",
            method: "post",
            data: { id: row.id },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/teacherend/school/classStudent",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getStudentList() {
      request({
        url: "/api/teacherend/student/list",
        method: "get",
        params: {
          limit: 999999999999999,
          college_id: this.college_id,
        },
      }).then((response) => {
        this.students = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        class_id: this.form.class_id,
        class_student_internship_log: {
          sex: "",
          contact_phone: "",
          now_internship_company: "",
          urgent_contact: "",
          urgent_contact_phone: "",
        },
      };
    },
    handleCreate() {
      this.$router.push(
        `/task/class_student?id=${this.form.class_id}&grade_id=${this.grade_id}&college_id=${this.college_id}`
      );
      // this.resetForm();
      // this.dialogStatus = "create";
      // this.dialogFormVisible = true;
      // this.$nextTick(() => {
      //   this.$refs["form"].clearValidate();
      // });
    },
    handleUpdate(row) {
      this.resetForm();
      this.form = Object.assign({}, row);
      if (!this.form.class_student_internship_log) {
        this.form.class_student_internship_log = {
          sex: "",
          contact_phone: "",
          now_internship_company: "",
          urgent_contact: "",
          urgent_contact_phone: "",
        };
      }
      console.log(row);
      this.form.class_student_internship_log.contact_phone = row.student.phone;
      console.log(this.form, "======================");
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/teacherend/school/classStudentInternshipStore",
            method: "post",
            data: {
              class_student_id: this.form.id,
              sex: this.form.class_student_internship_log.sex,
              student_no: this.form.student.account,
              now_internship_company:
                this.form.class_student_internship_log.now_internship_company,
              contact_phone:
                this.form.class_student_internship_log.contact_phone,
              urgent_contact:
                this.form.class_student_internship_log.urgent_contact,
              urgent_contact_phone:
                this.form.class_student_internship_log.urgent_contact_phone,
            },
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    handleCheckCurriculum() {
      this.$router.push(`/school/check_curriculum`);
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该院系, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/teacherend/school/classUpDown",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.filter-container {
  display: flex;
}
</style>
